/*
 * @Author       : zxlin
 * @Date         : 2024-01-17 10:28:50
 * @LastEditors  : zxlin
 * @LastEditTime : 2024-01-22 15:06:32
 * @FilePath     : \calendar-official-2024\src\main.js
 * @Description  : 
 */
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/styles/index.css'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/reset.css'
import 'amfe-flexible'
const app = createApp(App)
app.use(store)
app.use(router)
app.use(Antd)
app.mount('#app')
