<template>
  <Header></Header>
  <div
    class="area"
    :class="{ area1: current_name === 'MTeam' || current_name === 'MHome' }"
  >
    <router-view />
  </div>
  <!-- <Footer></Footer> -->
</template>
<script setup>
import Header from "./header";
// import Footer from './footer'
import { computed } from "vue";
import { useRoute } from "vue-router";
const route = useRoute();

const current_name = computed(() => {
  return route.name;
});
</script>
<style lang="less" scoped>
.area {
  padding-top: 112px;

  &.area1 {
    padding-top: 0;

    > div {
      height: 100vh;
    }
  }

  > div {
    height: calc(100vh - 112px);
  }
}
</style>
<style lang="less">
html.mobile,
body.mobile {
  min-width: auto !important;
}

.ant-drawer {
  z-index: 9;
  transform: translateZ(9px);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  // height: 100%;

  .ant-drawer-header {
    display: none;
  }

  .ant-drawer-mask {
    background: rgba(0, 0, 0, 0.45);
    height: 100vh;
    width: 100%;
  }

  .ant-drawer-content {
    height: 100vh;
  }

  .ant-drawer-content-wrapper {
    padding: 0;
    width: 474px !important;
    background-color: #fff;
    right: 0;
    top: 0;
    position: absolute;

    .ant-drawer-body {
      padding: 112px 0 0 0;
    }
  }
}

@keyframes sxd {
  0% {
    opacity: 0.5;
    transform: translateX(-50%) translateY(0);
  }

  50% {
    opacity: 1;
    transform: translateX(-50%) translateY(-14px);
  }

  100% {
    opacity: 0.5;
    transform: translateX(-50%) translateY(0);
  }
}
</style>
