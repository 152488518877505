import { createStore } from 'vuex'

export default createStore({
  state: {
    mobile_header_type: 1 // 移动端header类型 
  },
  getters: {
  },
  mutations: {
    setHeaderType: (state, data) => {
      state.mobile_header_type = data
    }
  },
  actions: {
  },
  modules: {
  }
})
