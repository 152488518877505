/*
 * @Author       : zxlin
 * @Date         : 2024-01-17 10:28:50
 * @LastEditors  : zxlin
 * @LastEditTime : 2024-01-25 10:04:02
 * @FilePath     : \calendar-official-2024\src\router\index.js
 * @Description  :
 */
import { createRouter, createWebHashHistory } from 'vue-router'
import MLayout from '../views/mobile/layout'
import Layout from '@/layout'
const routes = [
	{
		path: '/',
		name: 'Pc',
		component: Layout,
		redirect: '/home',
		children: [
			{
				path: 'home',
				name: 'Home',
				component: () => import(/* webpackChunkName: "pcHome" */ '../views/home')
			},
			{
				path: 'team',
				name: 'Team',
				component: () => import(/* webpackChunkName: "pc" */ '../views/team')
			},
			{
				path: 'dynamic',
				name: 'Dynamic',
				component: () => import(/* webpackChunkName: "pc" */ '../views/dynamic')
			},
			{
				path: 'job',
				name: 'Job',
				component: () => import(/* webpackChunkName: "pc" */ '../views/job')
			},
			{
				path: 'introduction',
				name: 'Introduction',
				component: () => import(/* webpackChunkName: "pc" */ '../views/introduction')
			}
		]
	},
	{
		path: '/mobile',
		component: MLayout,
		redirect: '/mobile/home',
		children: [
			{
				path: 'home',
				name: 'MHome',
				component: () => import(/* webpackChunkName: "mobileHome" */ '../views/mobile/home'),
			},
			{
				path: 'team',
				name: 'MTeam',
				component: () => import(/* webpackChunkName: "mobile" */ '../views/mobile/team'),
			},
			{
				path: 'dynamic',
				name: 'MDynamic',
				component: () => import(/* webpackChunkName: "mobile" */ '../views/mobile/dynamic'),
			},
			{
				path: 'job',
				name: 'MJob',
				component: () => import(/* webpackChunkName: "mobile" */ '../views/mobile/job'),
			},
			{
				path: 'introduction',
				name: 'MIntroduction',
				component: () => import(/* webpackChunkName: "mobile" */ '../views/mobile/introduction'),
			}
		],
	},
]

const router = createRouter({
	history: createWebHashHistory(),
	routes,
})

router.beforeEach((to, _, next) => {
	window.scrollTo(0, 0)
	next()
})

export default router
