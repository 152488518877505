<template>
  <div
    class="navbar no_select"
    :style="{
      backgroundColor: scrollState
        ? topTypeList[1].backgroundColor
        : topTypeList[topType].backgroundColor,
      color: scrollState ? topTypeList[1].color : topTypeList[topType].color,
      left: `${navbarLeft}px`,
    }"
  >
    <div class="left" @click="handleChangePage('/')">
      <img
        :src="
          require(`@/assets/imgs/logo_${
            topType === 1 ? 1 : scrollState ? 1 : 0
          }.png`)
        "
      />
    </div>
    <div class="right">
      <div
        class="page_item"
        :class="{
          page_item_active: item.path === pageActive,
        }"
        :style="{
          color:
            item.path === pageActive
              ? scrollState
                ? topTypeList[1].activeColor
                : topTypeList[topType].activeColor
              : undefined,
        }"
        v-for="item in pageList"
        @click="handleChangePage(item.path)"
      >
        <div
          class="page_item_border"
          :style="{
            backgroundColor:
              item.path === pageActive
                ? scrollState
                  ? topTypeList[1].activeColor
                  : topTypeList[topType].activeColor
                : undefined,
          }"
        ></div>
        {{ item.name }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watchEffect, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
const route = useRoute();
const router = useRouter();
const topType = ref(0); // 首页展示 0:透明,1:白色
const topTypeList = {
  0: {
    backgroundColor: 'transparent',
    color: '#fff',
    activeColor: '#fff',
  },
  1: {
    backgroundColor: '#fff',
    color: '#333333',
    activeColor: '#F44C1C',
  },
};
const scrollState = ref(false); // 是否滚动
const navbarLeft = ref(-window.scrollX);
onMounted(() => {
  window.addEventListener('scroll', function () {
    scrollState.value = window.scrollY === 0 ? false : true;
  });
  window.addEventListener('scroll', function () {
    if (navbarLeft.value !== -window.scrollX) {
      navbarLeft.value = -window.scrollX;
    }
  });
});
watchEffect(() => {
  if (['/home', '/team'].includes(route.path)) {
    topType.value = 0;
  } else {
    topType.value = 1;
  }
});
const pageActive = computed(() => {
  return route.path;
});
function handleChangePage(path) {
  router.push({ path, query: { ...route.query } });
}
const pageList = ref([
  {
    path: '/home',
    name: '首页',
  },
  {
    path: '/introduction',
    name: '产品',
  },
  {
    path: '/team',
    name: '团队',
  },
  {
    path: '/dynamic',
    name: '动态',
  },
  {
    path: '/job',
    name: '招聘',
  },
]);
</script>
<style scoped>
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-width: 1250px;
  height: 95px;
  z-index: 100;
  transform: translateZ(100px);
  padding: 0 110px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.left {
  width: 173px;
  cursor: pointer;
}
.right {
  display: flex;
  align-items: center;
  justify-content: end;
}
.right > div + div {
  margin-left: 110px;
}
.page_item {
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}
.page_item_active {
  font-size: 20px;
  font-weight: 500;
  position: relative;
}
.page_item_border {
  width: 100%;
  height: 4px;
  position: absolute;
  bottom: -10px;
  left: 0;
}
</style>
