<template>
  <div class="header" :class="{ header2: header_type == 2 }">
    <img class="logo" :src="header_type == 2 ? logo0 : logo1" />
    <a-drawer
      v-model:open="is_open"
      class="header-drawer"
      :maskClosable="false"
    >
      <div class="list">
        <div
          class="item"
          :class="{ active: item.name == current_name }"
          v-for="item in list"
          :key="item.name"
          @click="changeUrl(item)"
        >
          <p class="title">{{ item.title }}</p>
          <p class="xian"></p>
        </div>
      </div>
    </a-drawer>
    <img
      class="right-icon"
      v-show="!is_open && header_type == 1"
      src="@/assets/imgs/mobile/common/menu-icon.png"
      @click="doSth"
    />
    <img
      class="right-icon"
      v-show="!is_open && header_type == 2"
      src="@/assets/imgs/mobile/common/menu-iconw.png"
      @click="doSth"
    />
    <img
      class="right-icon"
      v-show="is_open"
      src="@/assets/imgs/mobile/common/cha.png"
      @click="doSth"
    />
  </div>
</template>
<script setup>
import logo0 from "@/assets/imgs/mobile/common/logo_0.png";
import logo1 from "@/assets/imgs/mobile/common/logo_1.png";
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
const store = useStore();
import { useRoute, useRouter } from "vue-router";
const route = useRoute();
const router = useRouter();
const is_open = ref(false);

let temp = 0;
const doSth = () => {
  is_open.value = !is_open.value;
  if (is_open.value) {
    temp = header_type.value;
    store.commit("setHeaderType", 1);
  } else {
    store.commit("setHeaderType", temp);
  }
};
const list = ref([
  { title: "首页", name: "MHome" },
  { title: "产品", name: "MIntroduction" },
  { title: "团队", name: "MTeam" },
  { title: "动态", name: "MDynamic" },
  { title: "招聘", name: "MJob" },
]);

onMounted(() => {
  dealType();
});
const current_name = computed(() => {
  dealType();
  return route.name;
});
const dealType = () => {
  if (route.name == "MTeam") {
    store.commit("setHeaderType", 2);
  } else {
    store.commit("setHeaderType", 1);
  }
};

const changeUrl = (item) => {
  router.push({ name: item.name });
  is_open.value = false;
  if (temp) {
    store.commit("setHeaderType", temp);
  }
};

// header类型
const header_type = computed(() => {
  return store.state.mobile_header_type;
});
</script>
<style lang="less" scoped>
img {
  display: block;
  pointer-events: auto;
}
.header {
  width: 100%;
  height: 112px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: #fff;
  z-index: 10;
  transform: translateZ(10px);
  &.header2 {
    background-color: transparent;
  }
  .logo {
    width: 246px;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
  .right-icon {
    width: 34px;
    height: auto;
    position: absolute;
    top: 50%;
    right: 40px;
    transform: translateY(-50%);
  }
}
.header-drawer {
  .list {
    width: 100%;
    .item {
      width: 100%;
      height: 126px;
      font-size: 32px;
      font-weight: 500;
      display: flex;
      justify-content: center;
      align-items: center;
      border-top: 2px solid #e6e6e6;
      position: relative;
      .title {
        margin-bottom: 0;
        color: #333333;
      }
      .xian {
        width: 4px;
        height: 100%;
        background-color: #f44c1c;
        position: absolute;
        top: 0;
        left: 0;
        display: none;
      }
      &.active {
        .title {
          color: #f44c1c;
        }
        .xian {
          display: block;
        }
      }
    }
    .item:last-child {
      border-bottom: 2px solid #e6e6e6;
    }
  }
}
</style>