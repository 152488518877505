<!--
 * @Author       : zxlin
 * @Date         : 2024-01-17 10:28:50
 * @LastEditors  : zxlin
 * @LastEditTime : 2024-01-24 17:05:11
 * @FilePath     : \calendar-official-2024\src\App.vue
 * @Description  : 
-->
<template>
  <router-view />
</template>

<script setup>
import { useRoute, useRouter } from 'vue-router';
const route = useRoute();
const router = useRouter();
// 判断PC/移动端
function handleCline() {
  const u = navigator.userAgent;
  const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
  const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
  let robState = isAndroid || isiOS ? 'mobile' : 'pc';
  let pathName = location.hash.slice(1).split('mobile').pop();
  if (robState === 'mobile') {
    router.replace({ path: '/mobile' + pathName, query: { ...route.query } });
    document.querySelector('html').className = 'mobile';
    document.body.className = 'mobile';
  } else {
    router.replace({ path: pathName, query: { ...route.query } });
    document.querySelector('html').className = '';
    document.body.className = '';
  }
}
handleCline();
</script>
