<!--
 * @Author       : zxlin
 * @Date         : 2024-01-18 14:02:58
 * @LastEditors  : zxlin
 * @LastEditTime : 2024-01-18 14:21:48
 * @FilePath     : \calendar-official-2024\src\components\FooterView.vue
 * @Description  : 
-->
<template>
  <div class="footer">
    <div class="footer_context">
      <div class="left">
        <div>
          <a
            href="http://youloft.com/address_youloft.html?calendar=1"
            target="_blank"
          >
            <div>地址：重庆市渝北区洪湖西路24号B幢9楼</div>
            <div class="border">
              <img :src="require('@/assets/imgs/footer_border.png')" />
            </div>
          </a>
        </div>
        <div>电话：023-88756859</div>
        <div>邮箱：xzb@youloft.com</div>
      </div>
      <div class="right">
        <div class="icon">
          <img :src="require('@/assets/imgs/footer_icon.png')" />
        </div>
        <div>
          <span>版权所有 ® 优路科技</span>
          <a
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=50019002500842"
            target="_blank"
          >
            渝公安备 50019002500842
          </a>
          <a href="https://beian.miit.gov.cn/" target="_blank">
            渝ICP备14005861号-1
          </a>
        </div>
        <div>Copyright 2010-2018 YOULOFT Tech.All Rights Reserved.</div>
      </div>
    </div>
  </div>
</template>

<script setup></script>
<style scoped>
.footer {
  height: 252px;
  background: #000000;
}
.footer_context {
  width: 960px;
  height: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.left,
.right {
  margin-top: 38px;
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
  line-height: 12px;
}
.left > div + div {
  margin-top: 18px;
}
.border {
  width: 12px;
  margin-left: 9px;
}
.left > div > a {
  display: flex;
  align-items: center;
}
a,
a:link,
a:visited,
a:hover,
a:active {
  text-decoration: none;
  color: inherit;
}
.icon {
  width: 114px;
}
.right {
  display: flex;
  flex-direction: column;
  align-items: end;
}

.right > div + div {
  margin-top: 10px;
}
</style>
